import { List, Map } from 'immutable';

import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';
import {
  isARtheme,
  isDBatTheme,
  isTexasTwelveTheme,
} from 'shared/utils/ThemeUtils';
import { isNative } from 'shared/utils/UserAgentUtils';

// Need to move these to a auth store/object at some point and start replacing the Utils version
// checks if the use is ANY of these roles
const AuthorizedUser = (roles = []) => {
  const r = List(roles);

  if (r.isEmpty()) {
    return true;
  }

  if (currentUser().isInstructor()) {
    return r.contains('Instructor');
  }
  if (currentUser().isManager()) {
    return r.contains('Manager');
  }

  if (currentUser().isCoach()) {
    return r.contains('Coach');
  }

  return r.contains(currentUser().role);
};

// Need to move these to a auth store/object at some point and start replacing the Utils version
// check if the customer has ALL of these roles
const AuthorizedCustomer = (features = []) => {
  const f = List(features);

  const enabledFlags = List(
    Map(currentCustomer().features)
      .filter(v => v)
      .keys()
  );

  return List(f).isSubset(enabledFlags);
};

// Need to move these to a auth store/object at some point and start replacing the Utils version
const Authorized = (roles = [], features = []) =>
  AuthorizedUser(roles) && AuthorizedCustomer(features);

/**
 * Required values:
 *   title: intl key of title of the nav item
 *   icon: key of the icon in the icons const in _SideNavMenuItem.jsx
 * Optional values:
 *   link: where the nav item will direct to
 *   subitems: list of subitems, following same pattern as this doc
 *   right: object containing fields to set up a right side action button
 *     icon: key of the icon in the icons const in _SideNavMenuItem.jsx
 *     action: key of the action in the actions const in _SideNavMenuItem.jsx
 *   useHref: force use of a link with href property
 *   useDeepLink: when 'link' option is a deep link need to set this option to true
 */
const SideNavItems = mobile => {
  let items = List();

  const dashboardAccess = !currentUser().isRestricted('dashboard');

  if (isLoggedIn() && dashboardAccess) {
    items = items.push({
      title: '.dashboard',
      icon: 'dashboard',
      link: customerScopedRoute('/dashboard'),
    });
  }

  const eventsAccess = !currentUser().isRestricted('events');

  if (isLoggedIn() && eventsAccess) {
    items = items.push({
      title: '.events',
      icon: 'event',
      link: customerScopedRoute('/events'),
    });
  }

  if (isLoggedIn() && Authorized([], ['beta_classes']) && eventsAccess) {
    items = items.push({
      title: '.classes',
      icon: 'classes',
      link: customerScopedRoute('/classes'),
    });
  }

  if (isLoggedIn() && Authorized([], ['teams']) && eventsAccess) {
    items = items.push({
      title: '.teams',
      icon: 'teams',
      link: customerScopedRoute('/teams'),
    });
  }

  const calendarAccess = !currentUser().isRestricted('calendar');

  if (isLoggedIn() && calendarAccess) {
    items = items.push({
      title: '.calendar',
      icon: 'calendar',
      link: customerScopedRoute('/calendar'),
    });
  }

  const contactsAccess = !currentUser().isRestricted('contacts');

  if (isLoggedIn() && contactsAccess) {
    // any one logged into customer view can see these
    items = items.push({
      title: '.contacts',
      icon: 'contacts',
      link: customerScopedRoute('/contacts'),
    });
  }

  const marketingAccess = !currentUser().isRestricted('marketing');

  if (isLoggedIn() && marketingAccess) {
    items = items.push({
      title: '.marketing',
      icon: 'marketing',
      link: customerScopedRoute('/marketing'),
    });
  }

  // REPORT SUB MENU

  const reportsSubnav = [];

  const contactsReportAccess = !currentUser().isRestricted('report_contacts');

  if (isLoggedIn() && !mobile && contactsReportAccess) {
    reportsSubnav.push({
      title: '.contacts_report',
      link: customerScopedRoute('/reports/contacts_report'),
    });
  }

  const payrollReportAccess = !currentUser().isRestricted('report_payroll');

  if (
    isLoggedIn() &&
    Authorized([], ['time_tracking', 'payroll']) &&
    payrollReportAccess
  ) {
    reportsSubnav.push({
      title: '.payroll_report',
      link: customerScopedRoute('/reports/payroll'),
    });
  }

  const ordersReportAccess = !currentUser().isRestricted('report_orders');
  const salesReportAccess = !currentUser().isRestricted('report_sales');
  const revenueReportAccess = !currentUser().isRestricted('report_revenue');
  const ledgerReportAccess = !currentUser().isRestricted('report_ledger');

  if (isLoggedIn() && ordersReportAccess) {
    reportsSubnav.push({
      title: '.orders_report',
      link: customerScopedRoute('/reports/orders'),
    });

    if (currentCustomer().mrr_enabled && !mobile) {
      reportsSubnav.push({
        title: '.monthly_recurring_revenue_report',
        link: customerScopedRoute('/reports/monthly_recurring_revenue_report'),
      });
    }
  }

  if (isLoggedIn() && ledgerReportAccess) {
    reportsSubnav.push({
      title: '.ledger_report',
      link: customerScopedRoute('/reports/ledger'),
    });
  }

  if (!mobile && salesReportAccess) {
    reportsSubnav.push({
      title: '.sales_detail',
      link: customerScopedRoute('/reports/sales_detail'),
    });
  }

  if (!mobile && revenueReportAccess) {
    reportsSubnav.push({
      title: '.revenue_report',
      link: customerScopedRoute('/reports/revenue_report'),
    });
  }

  const waiversReportAccess = !currentUser().isRestricted('report_waivers');

  if (!mobile && waiversReportAccess) {
    reportsSubnav.push({
      title: '.waivers_report',
      link: customerScopedRoute('/reports/waivers_report'),
    });
  }

  const inventoryReportAccess = !currentUser().isRestricted('report_inventory');

  if (
    isLoggedIn() &&
    AuthorizedCustomer(['retail']) &&
    !mobile &&
    inventoryReportAccess
  ) {
    reportsSubnav.push({
      title: '.inventory_report',
      link: customerScopedRoute('/reports/inventory'),
    });
  }

  const balancesReportAccess = !currentUser().isRestricted('report_balances');

  if (isLoggedIn() && balancesReportAccess) {
    reportsSubnav.push({
      title: '.balances_report',
      link: customerScopedRoute('/reports/balances'),
    });
  }

  const closeOutReportAccess = !currentUser().isRestricted('report_close_out');

  if (isLoggedIn() && closeOutReportAccess) {
    reportsSubnav.push({
      title: '.daily_closeout_report',
      link: customerScopedRoute('/reports/daily_closeout'),
    });
  }

  if (reportsSubnav.length > 0) {
    const rightIcon = Authorized(['Admin', 'StaffAdmin']) && {
      action: 'export',
      icon: 'export',
    };
    const reports = {
      title: '.reports',
      icon: 'reports',
      subitems: reportsSubnav,
      right: rightIcon,
    };

    items = items.push(reports);
  }

  // END REPORT SUB MENU

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin'], ['embedded_mode_report'])
  ) {
    const intelligenceSubnav = [];
    const { mode_report_labels: modeReportLabels } = currentCustomer();

    modeReportLabels.sort().map(label =>
      intelligenceSubnav.push({
        useTranslation: false,
        title: label,
        link: customerScopedRoute(`/reports/intelligence?label=${label}`),
      })
    );

    items = items.push({
      title: '.mode_report',
      icon: 'intelligence',
      subitems: intelligenceSubnav,
    });
  }

  const membershipsAccess = !currentUser().isRestricted('memberships');

  if (
    isLoggedIn() &&
    AuthorizedCustomer(['memberships']) &&
    membershipsAccess
  ) {
    items = items.push({
      title: '.memberships',
      icon: 'memberships',
      link: customerScopedRoute('/memberships'),
    });
  }

  const passesAccess = !currentUser().isRestricted('credit_passes');

  if (isLoggedIn() && AuthorizedCustomer(['credit_passes']) && passesAccess) {
    items = items.push({
      title: '.credit_passes',
      icon: 'credit_passes',
      link: customerScopedRoute('/credit_passes'),
    });
  }

  const resourcesAccess = !currentUser().isRestricted('resources');

  if (isLoggedIn() && AuthorizedCustomer(['resources']) && resourcesAccess) {
    items = items.push({
      title: '.resources',
      icon: 'resources',
      link: customerScopedRoute('/resources'),
    });
  }

  const retailAccess = !currentUser().isRestricted('retail');

  if (isLoggedIn() && AuthorizedCustomer(['retail']) && retailAccess) {
    items = items.push({
      title: '.retail',
      icon: 'retail',
      link: customerScopedRoute('/retail'),
    });
  }

  const couponsAccess = !currentUser().isRestricted('coupons');

  if (
    isLoggedIn() &&
    !(isTexasTwelveTheme() && Authorized(['Manager'])) &&
    couponsAccess
  ) {
    items = items.push({
      title: '.coupons',
      icon: 'coupons',
      link: customerScopedRoute('/coupons'),
    });
  }

  const settingsAccess = !currentUser().isRestricted('settings');

  if (isLoggedIn() && settingsAccess) {
    items = items.push({
      title: '.settings',
      icon: 'settings',
      link: customerScopedRoute('/settings'),
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'StaffMember', 'Manager']) &&
    isARtheme()
  ) {
    const isOnDevice = isNative();
    const subitems = [
      {
        title: '.ar_vision',
        link: isOnDevice
          ? 'pendo-ced707c0://'
          : 'https://apps.apple.com/us/app/athletic-republic-vision/id1541936144',
        useDeepLink: isOnDevice,
        useHref: !isOnDevice,
        target: !isOnDevice ? '_blank' : '_self',
      },
    ];

    items = items.push({
      title: '.assessment',
      icon: 'ar_vision',
      subitems,
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'StaffMember', 'Manager']) &&
    isDBatTheme()
  ) {
    items = items.push({
      title: '.university',
      icon: 'university',
      link: 'https://dbat.learnupon.com/users/sign_in?next=%2Fdashboard',
      useHref: true,
      target: '_blank',
    });
  }

  if (
    isLoggedIn() &&
    Authorized(['Admin', 'StaffAdmin', 'Manager'], ['add_ons'])
  ) {
    items = items.push({
      title: '.add_ons',
      icon: 'add_ons',
      link: customerScopedRoute('/add-ons'),
    });
  }

  return items;
};

export default SideNavItems;
