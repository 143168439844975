import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';

export const isUnauthorized = () => !isLoggedIn();
export const isAdmin = () => currentUser().isAdmin();
export const isStaff = () => currentUser().isStaff();
export const isStaffAdmin = () => currentUser().isStaffAdmin();
export const isManager = () => currentUser().isManager();
export const isClient = () => currentUser().isClient();
export const isInstructor = () => currentUser().isInstructor();
export const isCoach = () => currentUser().isCoach();

export const isStaffMemberDashboard = () =>
  currentUser().isPermittedEdit('dashboard');

export const isStaffMemberEventsEdit = () =>
  currentUser().isPermittedEdit('events');
export const isStaffMemberEventsView = () =>
  currentUser().isPermittedView('events');
export const isStaffMemberEvents = () =>
  isStaffMemberEventsView() || isStaffMemberEventsEdit();

export const transactionAllowedRoles = window.customer
  .enable_staff_member_refund
  ? [isStaff]
  : [isAdmin, isStaffAdmin];

export default {
  isAdmin,
  isStaff,
  isStaffAdmin,
  isManager,
  isClient,
  isInstructor,
  isUnauthorized,
  isCoach,
  isStaffMemberDashboard,
  isStaffMemberEventsEdit,
  isStaffMemberEventsView,
  transactionAllowedRoles,
};
